function minus (val) {
  if (val || val === 0) return val

  return '-'
}

function toNum (value) {
  if (typeof value === 'number') return value
  return +comma2Dot(value)
}

function comma2Dot (value) {
  if (typeof value === 'number') return value

  if (value) {
    return value.replace(/,/g, '.')
  }

  return value
}

function fullName (surname = '', name = '') {
  return `${ucFirst(surname)} ${ucFirst(name)}`.trim()
}

function ucFirst (str) {
  if (!str) return ''

  return str[0].toUpperCase() + str.slice(1)
}

function isString (value) {
  return typeof value === 'string'
}

function checkAndSliceIds (str) {
  return str.endsWith('_ids') ? sliceIds(str) : str
}

function sliceIds (str) {
  return str.slice(0, -4) // - _ids
}

export {
  minus,
  toNum,
  comma2Dot,
  fullName,
  ucFirst,
  isString,
  checkAndSliceIds,
  sliceIds
}
