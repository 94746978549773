<template>
  <label class="v-checkbox gap-xxs" :class="isDisabled && 'disabled'">
    <input
      v-model="modelValue"
      type="checkbox"
      class="v-checkbox__input"
      :name="name"
      :checked="modelValue"
      :disabled="isDisabled"
      @change="onChange"
    >

    <span class="v-checkbox__icon-wrap" :class="iconWrapClasses">
      <svgicon
        v-if="type === 'checkbox'"
        class="v-checkbox__icon"
        icon="checkbox"
        width="13"
        height="11"
      />
    </span>

    <span v-if="label" class="v-checkbox__title">
      {{ label }}
    </span>
    <!--  counter   -->
    <transition name="fade">
      <div v-if="counter" class="v-checkbox__counter" :class="'bg-color--' + counterColor">
        {{ counter }}
      </div>
    </transition>
  </label>
</template>

<script>
import { mixinInputModel } from '@/mixins/form/mixinInputModel'

export default {
  name: 'VCheckbox',
  mixins: [mixinInputModel],
  props: {
    isValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    counter: {
      type: Number,
      required: false,
      default: 0
    },
    counterColor: {
      type: String,
      default: 'red',
      validator: (value) => ['red', 'orange', 'green'].includes(value)
    }
  },
  computed: {
    iconWrapClasses () {
      return {
        checked: this.modelValue,
        'v-checkbox__icon-wrap--radio': this.type === 'radio',
        'v-checkbox__icon-wrap--switch': this.type === 'switch'
      }
    }
  },
  methods: {
    onChange (event) {
      const data = { id: Number(event.target.name), value: event.target.checked }
      this.$emit('value-changed', data)
    }
  }
}
</script>

<style lang="scss">
.v-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &__input {
    display: none;
  }

  &__label {
    color: $color__third;
  }

  &__icon-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include size(20px);
    border: 1px solid $color__input--border;
    border-radius: 4px;
    background-color: $color__checkbox--bg;
    transition-duration: var(--transition-duration);
    transition-property: box-shadow, background-color, border-color;
    transition-timing-function: ease-in-out;

    &.checked {
      border-color: $color__gray-blue;

      .v-checkbox__icon {
        opacity: 1;
        visibility: visible;
      }
    }

    &--radio {
      border-radius: 100%;

      &::after {
        content: '';
        @include absoluteCenter;
        @include size(12px);
        border-radius: 100%;
        background-color: $color__main;
        opacity: 0;
        visibility: hidden;
        transition-duration: var(--transition-duration);
        transition-timing-function: ease-in-out;
        transition-property: opacity, visibility;
      }

      &.checked::after {
        opacity: 1;
        visibility: visible;
      }
    }

    &--switch {
      width: 48px;
      height: 20px;
      border-radius: var(--border-radius);
      border: 1px solid $color__input--border;
      box-shadow:
        -4px -4px 4px rgba(255, 255, 255, 0.25),
        4px 4px 4px rgba(102, 153, 255, 0.25);

      &::after {
        content: '';
        position: absolute;
        display: block;
        @include size(14px);
        border-radius: 100%;
        background-color: $color__gray-blue;
        transform: translateX(-14px);
        transition: var(--transition-duration) transform ease-in-out;
      }

      &.checked {
        background-color: $color__main;
        box-shadow:
          -4px -4px 4px rgba(255, 255, 255, 0.25),
          4px 4px 4px rgba(102, 153, 255, 0.25),
          inset -4px -4px 4px #7DA8FF,
          inset 4px 4px 6px #5890FF;

        &::after {
          background: $color__checkbox--bg;
          transform: translateX(14px);
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $color__main;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    transition-duration: var(--transition-duration);
    transition-timing-function: ease-in-out;
    transition-property: opacity, visibility;
  }

  &:hover {
    .v-checkbox__icon-wrap {
      border-color: $color__main;
    }
  }

  &.disabled {
    pointer-events: none;
    filter: grayscale(100%);

    .v-checkbox__icon-wrap {
      border: 1px solid $color__input--border;
      background-color: var(--color__lavander);

      &--switch {
        box-shadow: none;
      }
    }

    .v-checkbox__label {
      color: $color__gray-blue;
    }
  }

  &__counter {
    position: initial;
    border-radius: 100px;
    padding: 3px 4px;
    min-width: 20px;
    font-size: 12px;
    line-height: 14px;
    color: $color__white;
  }

  &.filter__checkbox {
    .v-checkbox__title {
      color: $color__gray;
    }

    .v-checkbox__icon-wrap.checked {
      color: $color__cyan;
    }
  }
}
</style>
