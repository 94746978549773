import { RESET_SEARCH, SET_SEARCH } from '@/store/composition/modules/query/search/mutations'

const state = () => ({
  filterFormData: {},
  dateOrderCreate: '',
  search: ''
})

const mutations = {
  SET_FILTER_FORMDATA: (state, data) => {
    state.filterFormData = data
  },
  SET_SEARCH,
  RESET_SEARCH,
  SET_DATE_ORDER_CREATE: (state, value) => {
    state.dateOrderCreate = value
  },
  RESET_FILTER (state) {
    state.filterFormData = {
      sort: null,
      responsible: null,
      order_type: null,
      workshop: null,
      winding_method: null,
      spring_type: null,
      d_wires: null,
      is_pickup: null,
      documents_are_ready: null,
      reclamation: null,
      is_deleted: null
    }
  },
  RESET_DATE_ORDER_CREATE (state) {
    state.dateOrderCreate = ''
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
