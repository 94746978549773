import ProductApi from '@/api/catalog/ProductApi'
import FilterApi from '@/api/catalog/FilterApi'
import Product from '@/classes/model/Catalog/Product'
import { refreshArr } from '@/utils/array/reactive'
import { getPageCount } from '@/utils/store/pagination'
import { vuexBaseFile } from '@/store/composition/modules/file'
import { deleteFiles, updateFilesSort } from '@/store/composition/modules/file/actions'
import { showAlertError } from '@/utils/store/alert'

const state = () => ({
  ...vuexBaseFile.createState(),

  isEdit: false,
  products: [],
  currentProduct: null,
  filterValues: []
})

const getters = {}

const mutations = {
  ...vuexBaseFile.createMutations(),

  TURN_ON_IS_EDIT: state => {
    state.isEdit = true
  },
  TURN_OFF_IS_EDIT: state => {
    state.isEdit = false
  },
  TOGGLE_IS_EDIT: state => {
    state.isEdit = !state.isEdit
  },

  SET_PRODUCTS: (state, data) => {
    refreshArr(state.products, data.map(item => new Product(item)))
  },

  SET_FILTERVALUES: (state, data) => {
    state.filterValues = data
  },

  SET_CURRENT: (state, data) => {
    state.currentProduct = data
  },
  RESET_PRODUCTS: state => {
    state.products = []
  },
  RESET_CURRENT: state => {
    state.currentProduct = null
  }
}

const actions = {
  deleteFiles,
  updateFilesSort,

  async getProductsBy ({ commit, rootGetters }, categoryId) {
    try {
      commit('catalog/SET_IS_LOADING', true, { root: true })
      const params = rootGetters['catalog/getQueryParams']()
      // if (categoryId) params.sort = 'product_d_wire'
      const response = await ProductApi.getAll({
        category_id: categoryId,
        ...params
      })
      commit('SET_PRODUCTS', response.data)
      commit('catalog/SET_PAGE_COUNT', getPageCount(response), { root: true })
      const filter = await FilterApi.getAll({
        category_id: categoryId
      })
      commit('SET_FILTERVALUES', filter.data)
    } catch (e) {
      showAlertError(e)
    } finally {
      commit('catalog/SET_IS_LOADING', false, { root: true })
    }
  },

  async getProductById ({ commit }, id) {
    const response = await ProductApi.getById(id)
    commit('SET_CURRENT', response.data)
  },

  async createProduct ({ commit }, data) {
    const response = await ProductApi.create(data)

    commit('SET_CURRENT', response.data)
  },

  async updateProduct ({ commit }, { id, data }) {
    const response = await ProductApi.update(id, data)
    commit('SET_CURRENT', response.data)
  },

  async destroyProduct (_, id) {
    await ProductApi.destroy(id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
