import UserApi from '@/api/user/UserApi'
import { createBaseController } from '@/controllers/baseController'
import { createBaseResponseErrors } from '@/utils/error/responseErrors'
import { setAlertError } from '@/utils/store/alert'
import { goToPage } from '@/utils/router'

const USER_ERRORS = {
  ...createBaseResponseErrors('пользователя', 'пользователей'),
  login: 'Не удалось войти в систему',
  deactivate: 'Не удалось деактивировать пользователя'
}

export default {
  ...createBaseController(UserApi, USER_ERRORS),
  getById: async (id, params) => {
    try {
      return await UserApi.getById(id, params)
    } catch (e) {
      await setAlertError(e, USER_ERRORS.getById)
      await goToPage('NotFound')
    }
  },
  login: async data => {
    try {
      return await UserApi.login(data)
    } catch (e) {
      const message = e.response?.data?.message
      await setAlertError(e, message || USER_ERRORS.login)
    }
  },
  deactivate: async id => {
    try {
      return await UserApi.deactivate(id)
    } catch (e) {
      await setAlertError(e, USER_ERRORS.deactivate)
    }
  }
}
